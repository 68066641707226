import 'jquery-ui/themes/base/theme.css'
import 'jquery-ui/themes/base/datepicker.css'
import 'jquery-ui/ui/widgets/datepicker'
import 'jquery-ui/ui/i18n/datepicker-ja'

$(document).on('turbolinks:load', function(){
    $('.datepicker-here').datepicker({
        dateFormat: 'yy-mm-dd'
    })
})
